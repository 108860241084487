<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit, invalid }"
    class="flex flex-col w-full h-full items-center"
  >
    <form
      class="w-full flex flex-col items-center"
      @submit.prevent="handleSubmit(onSubmit(invalid))"
    >
      <Header2
        label="Auszahlungsinfo"
      />
      <PayoutForm
        class="w-full md:w-1/2 "
        :errorMessages="errorMessages"
        @onChange="onChange"
      />
      <Header2
        label="Bedingungen"
      />
      <div class="flex flex-col w-full md:w-1/2">
        <CheckboxField
          id="Datenschutzerklärung"
          v-model="form.dataPrivacy"
          type="text"
          container="mb-0"
          :rules="{ required: { allowFalse: false } }"
        >
          <template #labelSentence_>
            <label class="text-xs px-1 py-0 mt-0 font-bold text-gray-900 font-body">
              Ich akzeptiere die
              <a
                class="text-peach underline"
                href="https://about.epasnets.com/datenschutz/"
                target="_blank"
              >
                Datenschutzerklärung.
              </a>
            </label>
          </template>
        </CheckboxField>
        <CheckboxField
          id="Nutzungsbedingungen"
          v-model="form.terms"
          type="text"
          container="mb-0"
          :rules="{ required: { allowFalse: false } }"
        >
          <template #labelSentence_>
            <label class="text-xs px-1 py-0 mt-0 font-bold text-gray-900 font-body">
              Ich akzeptiere die
              <a
                class="text-peach underline"
                href="https://about.epasnets.com/agb/"
                target="_blank"
              >
                Nutzungsbedingungen.
              </a>
            </label>
          </template>
        </CheckboxField>
        <CheckboxField
          id="Kommision"
          v-model="form.commision"
          type="text"
          container="mb-5"
          :labelSentence="`Ich akzeptiere die Kommision von ${ GLOBAL_SETTING ? GLOBAL_SETTING.sales_commission_percentage : '5' }% des Umsatzes und ${ GLOBAL_SETTING ? $helpers.convertCurrency(GLOBAL_SETTING.sales_commission_euro) : '5,00 €' } pro Gutscheinverkauf zuzüglich 20% MwSt.`"
          :rules="{ required: { allowFalse: false } }"
        />
      </div>
      <div class="w-full sm:w-1/2 flex">
        <Button
          @onClick="back()"
          class="flex flex-col items-center mr-auto"
          type="button"
          label="vorheriger Schritt >"
          size="px-5 py-4"
          round="rounded"
        />
        <Button
          class="flex flex-col items-center ml-auto"
          type="submit"
          label="nächster Schritt >"
          size="px-5 py-4"
          round="rounded"
        />
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import Button from '_components/Button';
  import PayoutForm from '_components/Modules/Profile/Form/PayoutForm';
  import CheckboxField from '_components/Form/CheckboxField';
  import Header2 from '_components/Headers/Header2';

  export default {
    components: {
      PayoutForm,
      CheckboxField,
      Button,
      Header2
    },
    props: {
      errorMessages: {
        type: Array,
        default() {
          return []
        }
      }
    },
    data() {
      return {
        submitting: false,
        form: {
          dataPrivacy: false,
          terms: false,
          commission: false,
        }
      }
    },
    computed: {
      AUTH_USER()
      {
        return this.$store.getters.AUTH_USER
      },
      GLOBAL_SETTING()
      {
        return this.$store.getters.GLOBAL_SETTING
      },
    },
    watch: {
    },
    created() {
    },
    mounted() {
      document.getElementById('register-header').scrollIntoView();
    },
    methods: {
      back() {
        this.$emit('onChangeStep', {
            step: 3,
            form: this.form
          })
      },
      async onSubmit( isValid )
      {

        const valid = await this.$refs.observer.validate();
        if (!valid) {
          let errors = [];
          for (const [key, value] of Object.entries(this.$refs.observer.errors)){
            if (value.length) {
              errors.push(key);
            }
          }

          this.$swal({
            icon: 'warning',
            title: 'Dieses Feld muss ausgefüllt werden!',
            text: errors.toString(),
            confirmButtonColor: '#48BB78',
            confirmButtonText: 'Bestätigen'
          })
          return false
        }

        if( !isValid ) {
          this.$emit('onChangeStep', {
            step: 'done',
            form: this.form
          })
        }
      },
      onChange( data )
      {
        this.form = {
          ...this.form,
          ...data
        }
      },
    }
  }
</script>
<style lang='css' scoped>
</style>