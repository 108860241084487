<template>
  <div class="flex flex-col w-full">
    <InputField
      id="Passwort"
      class="mb-4"
      v-model="form.password"
      type="password"
      label="Passwort"
      rules="required|min:8|max:28"
      :errorMessages="errorMessages.password"
      @input="onChange"
    />
    <InputField
      id="Passwort wiederholen"
      class="mb-4"
      v-model="form.confirmPassword"
      type="password"
      label="Passwort wiederholen"
      rules="required|min:8|max:28|password:@Passwort"
      :errorMessages="errorMessages.confirmPassword"
      @input="onChange"
    />
  </div>
</template>
<script>
  import InputField from '_components/Form/InputField';

  export default {
    components: {
      InputField,
    },
    props: {
      errorMessages: {
        type: Array,
        default() {
          return []
        }
      }
    },
    data() {
      return {
        form: {
          password: '',
          confirmPassword: '',
        }
      }
    },
    mounted() {
    },
    methods: {
      onChange()
      {
        this.$emit('onChange', this.form)
      }
    }
  }
</script>
<style lang="css" scoped>
</style>